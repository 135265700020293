import {FormattedMessage, useIntl} from "gatsby-plugin-intl";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Formulario from "../components/formulario";
import {
    cerrado,
    costosSantaRosa,
    inputsSantaRosa,
    feriados,
    horasNaranjo
} from "../components/defaults";
import React from "react";

const Murcielago = () => {
    const sectorACG = "Murciélago";
    const intl = useIntl();
    const fechasCerrado = cerrado[sectorACG];
    return (
        <Layout sector={sectorACG}>
            <Seo title={intl.formatMessage({id: "mu.titulo"})}
                 description={intl.formatMessage({id: "mu.desc"})}
                 image={"/images/murcielago.wepp"}
            />
            <Grid container>
                <Grid item md={12}>
                    <Typography variant={"h6"} component={"h2"} >
                        {intl.formatMessage({id: "SMu"})}
                    </Typography> <br />
                    <Typography variant={"body2"}>{intl.formatMessage({id: "mu.info1"})} <br/>


                    </Typography> <br/>
                    <Typography variant={"body2"}>
                        <FormattedMessage
                            id="mu.info2"
                            values={{
                                a: text => <a href={"https://www.acguanacaste.ac.cr/turismo/sector-murcielago"}>{text}</a>,
                            }}
                        />
                    </Typography>
                </Grid>
            </Grid>
            <Formulario
                sector ={sectorACG}
                horas = {horasNaranjo}
                inputFields =  {inputsSantaRosa}
                costos = {costosSantaRosa}
                pago = "Pago con tarjeta al ingresar al Parque"
                diasPevios = {0}
                diaCerrado = {[3]}
                cerrado = {fechasCerrado}
                tipo={"turistas"}
                iva={1}
                feriados = {feriados}
            />
        </Layout>
    )
}

export default Murcielago